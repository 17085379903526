import { FunctionComponent, useState, useEffect } from "react";
import { Navbar, Nav, Table, Dropdown } from "react-bootstrap";
import { ReactComponent as LogoDark } from "../../assets/images/logo-dark.svg";
import { ReactComponent as LogoLight } from "../../assets/images/logo-light.svg";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import stylesButton from "../form/Input_.module.scss";
import classnames from "classnames/bind";
import { Panel, InputDeprecated as Input, Icon, Button, Skeleton, DownloadsButton } from "../../components";
import { useTheme } from "../../controllers/useTheme";
import { useHeader } from "../../controllers/useHeader";
import { TradingPairInfo } from "../../interfaces";
import {getDecimalsCount, formatNumber} from "../../views/utils";
import { getConfigByDomain } from "../../client/client";

const cn = classnames.bind(styles);
const cns = classnames.bind(stylesButton);


export const Header = ({
  showModal,
  showModalUpdateAuth,
  toggleModal,
  ...props
}: {
  showModal: boolean,
  showModalUpdateAuth?: boolean,
  toggleModal: (value: any) => void,
  [key: string]: any
}) => {
  const { updateLink, pairs, authorized, selectedPair, setSelectedPair, toggleFavouritePair } = useHeader();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [marketsOpen, setMarketsOpen] = useState<boolean>(false);
  const [favourites, setFavourites] = useState<any>({});
  const [filterType, setFilterType] = useState<string>('all');

  const handleChangeFilter = (event:React.FormEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  }

  const handlePairSelect = (e: React.MouseEvent<HTMLTableRowElement>, pair: TradingPairInfo<number>) => {
    e.stopPropagation();
    setMarketsOpen(false);
    setSelectedPair(pair);
  }

  //const themeCheckBox: any = document.getElementById('theme-switch');
  //const darkTheme = themeCheckBox && themeCheckBox.checked;

  const theme = useTheme();
  const Logo = theme === 'Dark' ? LogoLight : LogoDark;

  useEffect(() => {
    function onKeyup(e: any) {
      if (e.key === 'Escape') {
        setMarketsOpen(false);
      }
    }
    window.addEventListener('keyup', onKeyup);
  }, []);

  const updateInfoLink = updateLink ?
  <Dropdown className={cn("nav-item", "navbar-dropdown")}>
    <Dropdown.Toggle className={cn("nav-item", "navbar-dropdown")}>
      <><Icon icon={"download"} />{`Update to ${updateLink ? updateLink.versionAppLatest : 'none'}`}</>
    </Dropdown.Toggle>
    <Dropdown.Menu style={{minWidth: 50}}>
      {Object.keys(updateLink.link).map((linkKey)=>{
        return (
          <Dropdown.Item
            className={cn("nav-link", "update-link")}
            style={{width: 'fit-content'}}
            onClick={()=>window.open(updateLink.link[linkKey] ? updateLink.link[linkKey] : '#', '_blank')}
          >
            <><Icon icon={"download"} />{`.${linkKey}`}</>
          </Dropdown.Item>
        );
      })}
    </Dropdown.Menu>
  </Dropdown>
    /*<Nav.Link className={cn("nav-link", "update-link")} onClick={()=>window.open(updateLink ? updateLink.link : '#', '_blank')}>
      <><Icon icon={"download"} />{`Update to ${updateLink ? updateLink.versionAppLatest : 'none'}`}</>
    </Nav.Link>*/ : null;
  const helpLink =  <Nav.Link className={cn("nav-link", "surf-toggle")} onClick={()=>window.open('https://docs.flexdex.fi/', '_blank')}><><Icon icon={"arrow-up-right"} />Help</></Nav.Link>;

  return (<>
    <header className={cn("header")}>
      <Navbar expand="sm" expanded={expanded} className={cn("navbar")}>
        <Navbar.Brand className={cn("navbar-brand")} style={{paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
          <Link to="/" onClick={() => setExpanded(false)} className="logo" style={{paddingRight: 0}}>
            <Logo/>
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle className={cn("navbar-toggler", {"collapsed": !expanded})} aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
        <Navbar.Collapse id="basic-navbar-nav" className={cn("navbar-collapse")} style={{marginLeft: 0}}>
          <Nav className={cn("navbar-nav", "me-auto")}>
            <Dropdown show={marketsOpen} className={cn("nav-item", "navbar-dropdown")} style={{marginLeft: 0}}>
              <Dropdown.Toggle className={cn("nav-item", "navbar-dropdown")} onClick={(e: any) => {e.stopPropagation();setMarketsOpen(true);}}>
                Markets<Icon icon={"chevron-vertical"} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Panel
                  className={cn("header-panel")}
                  header={
                  <Nav variant="pills" defaultActiveKey="all" className={cn("head-narrow head-narrow-note", "nav-pills nav-pills-narrow")}>
                    <Nav.Item>
                      <Nav.Link className={cn(`nav-link-pill ${filterType === 'favourites' ? 'active' : ''}`)} onClick={()=>setFilterType("favourites")}><Icon icon={`favorite-off`}/></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className={cn(`nav-link-pill ${filterType === 'all' ? 'active' : ''}`)} onClick={()=>setFilterType("all")}>All</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className={cn(`nav-link-pill ${filterType === 'ever' ? 'active' : ''}`)} onClick={()=>setFilterType("ever")}>EVER</Nav.Link>
                    </Nav.Item>
                  </Nav>
                }>
                  <Input<{filter: string}>
                    name={"filter"}
                    value={filter}
                    onChange={handleChangeFilter}
                    icon={"search"}
                    action={<Button className={cns("clear-button", {"active": filter})} type="button" onClick={() => setFilter("")}><Icon icon="close-alt"/></Button>}
                    placeholder={"Filter"}
                  />

                  <Table className={cn("pairs-menu")}>
                    <thead>
                      <tr>
                        <th className="paragraph paragraph-footnote">Pair</th>
                        <th className="paragraph paragraph-footnote right">Price</th>
                        <th className="paragraph paragraph-footnote right">Change</th>
                      </tr>
                    </thead>
                    <tbody className="mono-narrow mono-narrow-note">
                      {pairs
                        .filter((pair) => //filter by search input
                          pair.ticker.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
                        )
                        .filter((pair) => {//filter by filterType
                          //console.log(`filterType=${filterType}`)
                          if (filterType === 'all') return true;
                          if (filterType === 'ever') {
                            return pair.minor === 'EVER';
                          }
                          if (filterType === 'favourites') {
                            return !!favourites[pair.ticker];
                          }
                        })
                        .map((pair, index) => {
                        const [from, to] = pair.ticker.split("/");
                        const price = pair.price;
                        const open = pair.open;
                        const strPrice = pair ?
                          formatNumber(pair.price, getDecimalsCount(pair.minMove/pair.priceScale)) :
                          '';

                        if (selectedPair && pair.address === selectedPair.address) {
                          return <tr key={index}>
                            <td>
                              <button style={favourites[pair.ticker] ? {} : undefined} onClick={() => {
                                if (!favourites[pair.ticker]) {
                                  setFavourites({...favourites, [pair.ticker]: true});
                                }
                                else {
                                  setFavourites({...favourites, [pair.ticker]: false});
                                }
                              }}>
                                <Icon icon={`favorite-${favourites[pair.ticker] ? 'on' : 'off'}`}/>
                              </button>
                              &nbsp;
                              {from}<em>/</em>
                              {to}
                            </td>
                            <td className="right">{price ? strPrice : <Skeleton wide="half" />}</td>
                            <td className={cn("right", {"green": price - open > 0, "red": price - open < 0})}>{price ? <>{price - open > 0 ? "+" : ""}{((price - open)*100/open).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2})}{"\u2009%"}</> : <Skeleton wide="triplet" />}</td>
                          </tr>;
                        } else {
                          return <tr key={index} onClick={(e) => handlePairSelect(e, pair)}>
                            <td><button onClick={(e) => {
                              e.stopPropagation();
                              if (!favourites[pair.ticker]) {
                                setFavourites({...favourites, [pair.ticker]: true});
                              }
                              else {
                                setFavourites({...favourites, [pair.ticker]: false});
                              }
                            }}><Icon icon={`favorite-${favourites[pair.ticker] ? 'on' : 'off'}`}/></button>&nbsp;{from}<em>/</em>{to}</td>
                            <td className="right">{price ? strPrice : <Skeleton wide="half" />}</td>
                            <td className={cn("right", {"green": price - open > 0, "red": price - open < 0})}>{price ? <>{price - open > 0 ? "+" : ""}{((price - open)*100/open).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2})}{"\u2009%"}</> : <Skeleton wide="triplet" />}</td>
                          </tr>;
                        }
                      })}
                    </tbody>
                  </Table>
                </Panel>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          {getConfigByDomain().authDisabled && <DownloadsButton navlink />}
          {!getConfigByDomain().authDisabled && <Nav.Link
            className={cn("nav-link", "surf-toggle",{"nav-link-default": showModal})}
            onClick={toggleModal}
          >
            {showModalUpdateAuth ? null : showModal ? <><Icon icon={"close"} />Close</> :
            (authorized ? <><Icon icon={"qrcode"} />Surf</> : <><Icon icon={"connect"} />Connect Surf</>)}
          </Nav.Link>}
          {showModal || showModalUpdateAuth ? null : helpLink}
          {showModal || showModalUpdateAuth ? null : updateInfoLink}
          {showModal || showModalUpdateAuth ? null : <label htmlFor="theme-switch" className="switch-label"/>}
        </Navbar.Collapse>

        <Dropdown show={marketsOpen} className={cn("nav-item", "navbar-dropdown", "d-block d-sm-none")}>
          <Dropdown.Toggle className={cn("nav-item", "navbar-dropdown")} onClick={(e: any) => {e.stopPropagation();setMarketsOpen(true);}}>
            Markets<Icon icon={"chevron-vertical"} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Panel
              className={cn("header-panel")}
              header={
              <Nav variant="pills" defaultActiveKey="all" className={cn("head-narrow head-narrow-note", "nav-pills nav-pills-narrow")}>
                <Nav.Item>
                  <Nav.Link className={cn("nav-link-pill")}><Icon icon="favorite"/></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className={cn("nav-link-pill active")}>All</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className={cn("nav-link-pill")}>EVER</Nav.Link>
                </Nav.Item>
              </Nav>
            }>
            <Input<{filter: string}>
                name={"filter"}
                value={filter}
                onChange={handleChangeFilter}
                icon={"search"}
                action={<Button className={cns("clear-button", {"active": filter})} type="button" onClick={() => setFilter("")}><Icon icon="close-alt"/></Button>}
                placeholder={"Filter"}
              />

              <Table className={cn("pairs-menu")}>
                <thead>
                  <tr>
                    <th className="paragraph paragraph-footnote">Pair</th>
                    <th className="paragraph paragraph-footnote right">Price</th>
                    <th className="paragraph paragraph-footnote right">Change</th>
                  </tr>
                </thead>
                <tbody className="mono-narrow mono-narrow-note">
                  {pairs.filter((pair) =>
                    pair.ticker.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
                  ).map((pair, index) => {
                    const [from, to] = pair.ticker.split("/");
                    const price = pair.price;
                    const open = pair.open;
                    const strPrice = pair ?
                      formatNumber(pair.price, getDecimalsCount(pair.minMove/pair.priceScale)) :
                      '';
                    if (selectedPair && pair.address === selectedPair.address) {
                      return <tr key={index}>
                        <td><button onClick={() => toggleFavouritePair(pair)}><Icon icon="favorite"/></button>&nbsp;{from}<em>/</em>{to}</td>
                        <td className="right">{price ? strPrice : <Skeleton wide="half" />}</td>
                        <td className={cn("right", {"green": price - open > 0, "red": price - open < 0})}>{price ? <>{price - open > 0 ? "+" : ""}{((price - open)*100/open).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2})}{"\u2009%"}</> : <Skeleton wide="triplet" />}</td>
                      </tr>;
                    } else {
                      return <tr key={index} onClick={(e) => handlePairSelect(e, pair)}>
                        <td><button onClick={() => toggleFavouritePair(pair)}><Icon icon="favorite"/></button>&nbsp;{from}<em>/</em>{to}</td>
                        <td className="right">{price ? strPrice : <Skeleton wide="half" />}</td>
                        <td className={cn("right", {"green": price - open > 0, "red": price - open < 0})}>{price ? <>{price - open > 0 ? "+" : ""}{((price - open)*100/open).toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2})}{"\u2009%"}</> : <Skeleton wide="triplet" />}</td>
                      </tr>;
                    }
                  })}
                </tbody>
              </Table>
            </Panel>
          </Dropdown.Menu>
        </Dropdown>

        <div className={cn("d-block d-sm-none")} style={{width: '100%'}}>
          <div className={cn("d-flex flex-row ps-2 pe-3")} style={{justifyContent: 'space-between'}}>
            {getConfigByDomain().authDisabled && <DownloadsButton navlink />}
            {!getConfigByDomain().authDisabled && <Nav.Link
              className={cn("nav-link", "surf-toggle", "d-block d-sm-none", {"nav-link-default": showModal})}
              onClick={toggleModal}
            >
              {
                showModal ?
                <><Icon icon={"close"} />Close</> :
                (authorized ?
                <><Icon icon={"qrcode"} />Surf</> :
                <><Icon icon={"connect"} />Connect Surf</>)
              }
            </Nav.Link>
            }
            {showModal || showModalUpdateAuth ? null : helpLink}
            {showModal || showModalUpdateAuth ? null : updateInfoLink}
            {showModal || showModalUpdateAuth ? null : <label htmlFor="theme-switch" className="switch-label" style={{paddingTop: 8}}/>}
          </div>
        </div>
      </Navbar>
    </header>
    <div onClick={() => setMarketsOpen(false)} className={`fade modal-backdrop menu-backdrop${marketsOpen ? " show" : ""}`}></div>
    </>
  );
};

export default Header;
