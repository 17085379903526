import React, { useState } from "react";
import styles from "./Authenticate.module.scss";
import classnames from "classnames/bind";
import { Overlay, Icon, Button, InputDeprecated as Input } from "../../components";
import { ReactComponent as Logo } from "../../assets/images/logo-flash.svg";
import { getConfigByDomain } from "../../client/client";

import {  } from "../../interfaces";
import { Formik } from "formik";

const cn = classnames.bind(styles);
const enableCustomKeys = getConfigByDomain().enableCustomKeys;

interface IAuthPropsArgs {
  username: string,
  password: string,
  keyPublic?: string,
  keyPrivate?: string,
}

export interface IAuthProps {
  handleEnter: (value: IAuthPropsArgs) => void,
  handleLogout: () => void,
  mode: "signup" | "signin",
  error: boolean | string
}

export const Authenticate = ({
  handleEnter,
  handleLogout,
  mode,
  error
}: IAuthProps) => {
  const [password, setPassword] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [keyPublic, setKeyPublic] = useState<string>("");
  const [keyPrivate, setKeyPrivate] = useState<string>("");
  const [errorValidate, setErrorValidate] = useState<string>("");
  const [masked, setMasked] = useState<boolean>(true);
  const [showKeys, setShowKeys] = useState<boolean>(false);

  const handleClose = () => null;

  const handleChangePass = (event:React.FormEvent<HTMLInputElement>) => {
    setErrorValidate('');
    setPassword(event.currentTarget.value);
  }
  const handleChangeUsername = (event:React.FormEvent<HTMLInputElement>) => {
    setErrorValidate('');
    setUsername(event.currentTarget.value);
  }

  const handleChangeKeyPublic = (event:React.FormEvent<HTMLInputElement>) => {
    setErrorValidate('');
    setKeyPublic(event.currentTarget.value);
  }
  const handleChangeKeyPrivate = (event:React.FormEvent<HTMLInputElement>) => {
    setErrorValidate('');
    setKeyPrivate(event.currentTarget.value);
  }

  const submit = () => {
    //console.log(`check username=${username} password=${password}`);
    const isUsernameValid = username && username.trim().length >= 2;
    const isPasswordValid = password && password.trim().length >= 5;
    if (!isUsernameValid || !isPasswordValid) {
      setErrorValidate('Enter credentials - min 2 characters for Username and 5 characters for Password');
      return;
    }

    handleEnter({
      username,
      password,
      keyPublic,
      keyPrivate,
    });
  }

  return (
    // <Formik
    //   initialValues={{
    //     username: "",
    //     password: ""
    //   }}
    //   onSubmit={() => {}}
    // >
        <div className={cn("enter-form")}>
          {mode === "signup"
            ? <>
                <h3>Your credentials</h3>
                <div className={cn("description", "paragraph", "paragraph-footnote")}>{`Entering new username and password \nwill create new account`}</div>
              </>
            : <>
                <h3>Sign in</h3>
                <div className={cn("description", "paragraph", "paragraph-footnote")}>{`Enter username and password to sign in into your account`}</div>
              </>
          }

          <Input<{username: string}>
            className={cn("input")}
            name={"username"}
            value={username}
            type={"text"}
            onChange={handleChangeUsername}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                submit();
              }
            }}
            placeholder={"Username"}
          />
          <Input<{password: string}>
            className={cn("input")}
            name={"password"}
            value={password}
            type={masked ? "password" : "text"}
            onChange={handleChangePass}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                submit();
              }
            }}
            action={<Button className={cn("show-button")} variant="transparent" type={"button"} onClick={() => setMasked(!masked)}>{masked ? <Icon icon="eye-crossed"/> : <Icon icon="eye"/>}</Button>}
            placeholder={"Password"}
          />

          {mode === "signup" && enableCustomKeys && <a
              href="#"
              className={cn("paragraph", "paragraph-footnote", "note", "mb-3")}
              onClick={(e) => {
                setShowKeys(!showKeys);
              }}
            >Set custom keys for trader (advanced)</a>
          }

          {showKeys && <Input<{keyPublic: string}>
            className={cn("input")}
            name={"keyPublic"}
            value={keyPublic}
            type={"text"}
            onChange={handleChangeKeyPublic}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                submit();
              }
            }}
            placeholder={"Public key"}
          />}

          {showKeys && <Input<{keyPrivate: string}>
            className={cn("input")}
            name={"keyPrivate"}
            value={keyPrivate}
            type={"text"}
            onChange={handleChangeKeyPrivate}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                submit();
              }
            }}
            placeholder={"Private key"}
          />}

          <Button
            variant="primary"
            glowing={true}
            size="lg"
            disabled={false}
            className={cn("btn-enter")}
            onClick={() => {
              submit();
            }}
          >
            <span>Enter</span><Icon icon="arrow-right"/>
          </Button>
          {!!errorValidate && <div className={cn("paragraph", "paragraph-footnote", "error", "note")}>{errorValidate}</div>}
          {error
            ? <div className={cn("paragraph", "paragraph-footnote", "error", "note")}>{error}</div>
            : (mode === "signin" &&
            <a
              href="#"
              className={cn("paragraph", "paragraph-footnote", "note")}
              onClick={(e) => {
                handleLogout();
                window.location.reload();
              }}
            >Delete account and start a new session</a>)
          }
        </div>
      // </Formik>
  );
}

export default Authenticate;
