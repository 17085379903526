export const authabi = `{
	"ABI version": 2,
	"version": "2.3",
	"header": ["time"],
	"functions": [
		{
			"name": "onGetInvokeUnwrapMessage",
			"inputs": [
				{"name":"message","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetFlexClientAndUserId",
			"inputs": [
				{"name":"flexClient","type":"optional(address)"},
				{"name":"userId","type":"optional(uint256)"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetUserIdAddress",
			"inputs": [
				{"name":"addr","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetInvokeAuthMessage",
			"inputs": [
				{"name":"message","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetInvokeUnwrapMessage",
			"inputs": [
				{"name":"message","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onIsUpdateNeeded",
			"inputs": [
				{"name":"res","type":"bool"}
			],
			"outputs": [
			]
		},
		{
			"name": "constructor",
			"inputs": [
			],
			"outputs": [
			]
		}
	],
	"data": [
	],
	"events": [
	],
	"fields": [
		{"name":"_pubkey","type":"uint256"},
		{"name":"_timestamp","type":"uint64"},
		{"name":"_constructorFlag","type":"bool"}
	]
}`;

export const tradeabi = `{
    "ABI version": 2,
    "version": "2.2",
    "header": ["time"],
    "functions": [
        {
            "name": "onTrade",
            "inputs": [
                {"name":"status","type":"uint8"},
                {"name":"sdkError","type":"uint32"},
                {"name":"exitCode","type":"uint32"}
            ],
            "outputs": [
            ]
        },
				{
					"name": "onMarketTrade",
					"inputs": [
						{"name":"executedAmount","type":"uint128"}
					],
					"outputs": [
					]
				},
				{
						"name": "onCancelOrder",
						"inputs": [
								{"name":"status","type":"uint8"},
								{"name":"sdkError","type":"uint32"},
								{"name":"exitCode","type":"uint32"}
						],
						"outputs": [
						]
				},
        {
            "name": "onSBox",
            "inputs": [
                {"name":"result","type":"uint32"},
                {"name":"key","type":"uint256"}
            ],
            "outputs": [
            ]
        },
        {
            "name": "constructor",
            "inputs": [
            ],
            "outputs": [
            ]
        }
    ],
    "data": [
    ],
    "events": [
    ],
    "fields": [
        {"name":"_pubkey","type":"uint256"},
        {"name":"_timestamp","type":"uint64"},
        {"name":"_constructorFlag","type":"bool"}
    ]
}`;

const abis = {
	authabi,
	tradeabi,
};

export default abis;
