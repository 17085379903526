import React, { useEffect, ReactNode, useRef, useState, useMemo, InputHTMLAttributes } from "react";
//import React, { useState, useEffect, useRef, ReactNode, InputHTMLAttributes } from "react";
// import ReactSelect from 'react-select';
// import { CommonProps, GroupBase } from 'react-select';
// import OptionType from 'react-select';
import styles from './Input.module.scss';
import { FormikContextType, useFormikContext, Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { templateParser, templateFormatter, parseDigit } from 'input-format'
import ReactInput from 'input-format/react'

import { Icon } from "..";
// /import useOutsideClick from "../../utils/outsideClickCallback";
import { FormGroupProps } from "../../interfaces";
import { Button } from "../../components";
import classnames from "classnames/bind";
import { FlexContainer } from "../flex";

const cnb = classnames.bind(styles);

const PHONE_TEMPLATE = '(xxx) xxx-xxxx';
const CARD_TEMPLATE = 'xxxx xxxx xxxx xxxx';
const CARD_DATE_TEMPLATE = 'xx / xx';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
  title?: string,
  caption?: string,
  icon?: string,
  status?: "error" | "success" | "loading" | "validate" | undefined,
  composition?: "substitute" | "slide" | "duplet",
  units?: ReactNode,
  clear?: boolean,
  validation?: string | boolean | undefined,
  action?: ReactNode,
  autoComplete?: "on" | "off" | undefined
}

export const InputFC = <FormValues, >({
  title,
  label,
  placeholder,
  caption,
  clear,
  composition,
  validation,
  icon,
  units,
  error,
  status,
  disabled,
  autoComplete,
  name,
  className,
  action,

  onChange: onChangeProp,

  format,
  parse,
  mask,

  field,
  form,

  ...props
} : FormGroupProps & FieldProps & Omit<InputProps, "name"> & {
  name: keyof FormValues,
  format?: () => any,
  parse?: () => any,
  mask?: string
}) => {

  const onChange = onChangeProp
    ?
      (e: any) => {
        onChangeProp(e);
        field.onChange(e);
      }
    : field.onChange;

  const {
    setFieldValue
  } : {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  } = useFormikContext<FormValues>();

  const [active, setActive] = useState<string>();
  const [focused, setFocused] = useState<boolean>(false);

  const refIcon = useRef<HTMLDivElement>(null);
  const refUnits = useRef<HTMLDivElement>(null);
  const refAction = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("focus", () => setFocused(true));
      ref.current.addEventListener("blur", () => setFocused(false));
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("focus",  () => setFocused(true));
        ref.current.removeEventListener("blur",  () => setFocused(false));
      }
    }
  }, []);

  useEffect(() => {
    if (Boolean(units && refUnits && ref && ref.current && ref.current.style)) {
      // ref.current?.style({paddingRigh  t: });
      // ref.current.addEventListener("blur", () => setFocused(false));
      ref.current!.style!.paddingRight! = (Number(refUnits.current?.offsetWidth) + 8) + "px";
    }
    if (Boolean(action && refAction && ref && ref.current && ref.current.style)) {
      ref.current!.style!.paddingRight! = (Number(refAction.current?.offsetWidth) + 8) + "px";
      // ref.current.addEventListener("blur", () => setFocused(false));
    }
  }, [units, field.value, action, refAction, refUnits]);

  return <>
    <div className={cnb("input", className, {"input-disabled": disabled}, {"input-validation": Boolean(validation)}, {"input-icon": icon}, composition ? "input-" + composition : undefined)}>
      {title && <div className={cnb("paragraph paragraph-footnote", "input-title")}>
        <>{title}</>
      </div>}

      <div className={cnb("input-group", {"input-group-focused": focused}, {"input-group-active": Boolean(field.value)})}>
        {icon && <div className={cnb("input-icon")}>
          <Icon icon={icon} />
        </div>}
        {clear && <div
          className={cnb("input-clear")}
        >
          <Button
            onClick={() => {
              setFieldValue(String(field.name), "");
            }}
          >
            <Icon icon={"close-alt"} />
          </Button>
        </div>}
        {(() => {
          switch (composition) {
            case "substitute":
              return mask
                ? <ReactInput
                  disabled={disabled}
                  className={cnb("input-component")}
                  placeholder={placeholder}
                  autoComplete={autoComplete || "on"}
                  value={field.value}
                  // TODO Why Formik onChange callback's not working? ReactInput's param is a value not an event
                  onChange={({value}: any) => setFieldValue(String(field.name), value)}
                  parse={templateParser(mask, parseDigit)}
                  format={templateFormatter(mask)}
                />
                : <input
                  ref={ref}
                  disabled={disabled}
                  className={cnb("input-component")}
                  placeholder={placeholder}
                  autoComplete={autoComplete || "on"}
                  {...field}
                  onChange={onChange}
                />

            case "slide":
              return <>
                <label className={cnb("input-label", "paragraph", "paragraph-normal")}>{label}</label>
                {format && parse
                ? <ReactInput
                  disabled={disabled}
                  className={cnb("input-component")}
                  placeholder={placeholder}
                  autoComplete={autoComplete || "on"}
                  value={field.value}
                  // TODO Why Formik onChange callback's not working? ReactInput's param is a value not an event
                  onChange={({value}: any) => setFieldValue(String(field.name), value)}
                  parse={parse}
                  format={format}
                />
                : <input
                  ref={ref}
                  disabled={disabled}
                  className={cnb("input-component")}
                  placeholder={placeholder}
                  autoComplete={autoComplete || "on"}
                  {...field}
                  onChange={onChange}
                />}
              </>

            case "duplet":
              return <FlexContainer
                direction="column"
                className={cnb("flex-container")}
              >
              <label className={cnb("input-label", "paragraph", "paragraph-normal")}>{label}</label>

              {format && parse
                ? <ReactInput
                  disabled={disabled}
                  className={cnb("input-component")}
                  placeholder={placeholder}
                  autoComplete={autoComplete || "on"}
                  value={field.value}
                  // TODO Why Formik onChange callback's not working? ReactInput's param is a value not an event
                  onChange={({value}: any) => setFieldValue(String(field.name), value)}
                  parse={parse}
                  format={format}
                />
                : <input
                  ref={ref}
                  disabled={disabled}
                  className={cnb("input-component")}
                  placeholder={placeholder}
                  autoComplete={autoComplete || "on"}
                  {...field}
                  onChange={onChange}
                />}
              </FlexContainer>

            default:
              break;
          }
        })()}
        {units && <div ref={refUnits} className={cnb("input-units", "paragraph", "paragraph-normal")}>
          {units}
        </div>}
        {action && <div ref={refAction} className={cnb("input-action")}>
          {action}
        </div>}
      </div>
      {(caption || validation) && <div className={cnb("input-caption", "paragraph", "paragraph-tiny")}>
        {Boolean(validation) ? validation : caption}
      </div>}
    </div>
  </>;
};


export const InputNumber = <FormValues, >({
  title,
  label,
  placeholder,
  caption,
  clear,
  composition,
  icon,
  units,
  error,
  status,
  disabled,
  name,
  className,
  autoComplete,
  action,
  valueTester,
  ...props
} : FormGroupProps & Omit<InputProps, "name"> & {
  name: keyof FormValues
  valueTester?: any,
}) => {

  const {
    values,
    errors,
    touched,
    handleChange,
    handleReset,
    setFieldValue,
    setFieldTouched,
  } : {
    values: any,
    errors: FormikErrors<FormValues>,
    handleChange: any,
    handleReset: any,
    setFieldTouched: any,
    handleBlur: any,
    touched: FormikTouched<FormValues>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  } = useFormikContext<FormValues>();

  const [value, setValue] = useState<number>(Number(values[name]));
  const regExp = /^[0-9]+\.?[0-9]*$/;
  const regExpTrim = /^0+[0-9]+\.?[0-9]*$/;

  useEffect(() => {
    return () => {

    }
  }, [])

  return <>
    <Field
      name={String(name)}

      title={title}
      label={label}
      placeholder={placeholder}
      caption={caption}
      clear={clear}
      composition={composition}
      icon={icon}
      units={units}
      action={action}
      status={status}
      autoComplete={autoComplete}
      validation={errors[name]}

      onChange={(e: React.ChangeEvent<any>) => {
        const event = e.nativeEvent as InputEvent;
        if (event.inputType === "insertText") {
          if (!(e.target.value.match(regExp) && e.target.value.match(regExp).length)) {
            e.currentTarget.value = value
          } else {
            if (e.target.value.match(regExpTrim) && e.target.value.match(regExpTrim).length) {
              e.currentTarget.value = parseFloat(e.target.value as string);
            }
          }
        }
        if (valueTester) e.currentTarget.value = valueTester(e.currentTarget.value);
        setValue(Number(e.currentTarget.value));
      }}
      component={InputFC}
      disabled={disabled}
      className={className}

      {...props}
    />
  </>;
};

export const InputMasked = <FormValues, >({
  title,
  mask,
  label,
  placeholder,
  caption,
  clear,
  composition,
  icon,
  units,
  error,
  status,
  disabled,
  name,
  className,
  action,
  ...props
} : FormGroupProps & Omit<InputProps, "name"> & {
  name: keyof FormValues,
  mask: string
}) => {

  const {
    values,
    errors,
  } : {
    values: any,
    errors: FormikErrors<FormValues>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  } = useFormikContext<FormValues>();

  const parse = useMemo(() => templateParser(mask, parseDigit), [mask, parseDigit]);
  const format = useMemo(() => templateFormatter(mask), [mask]);

  return <>
    <Field
      name={String(name)}

      title={title}
      label={label}
      placeholder={placeholder || ""}
      caption={caption}
      clear={clear}
      composition={composition}
      icon={icon}
      parse={parse}
      format={format}
      mask={mask}
      units={units}
      action={action}
      validation={false}

      onChange={(e: any) => {
        console.log(e)
      }}

      component={InputFC}
      disabled={disabled}
      className={className}

      {...props}
    />
  </>;
};

export const Input = <FormValues, >({
  title,
  label,
  placeholder,
  caption,
  clear,
  composition,
  icon,
  units,
  error,
  status,
  disabled,
  name,
  className,
  action,
  ...props
} : FormGroupProps & Omit<InputProps, "name"> & {
  name: keyof FormValues
}) => {

  const {
    values,
    errors,
    touched,
    handleReset,
    setFieldValue,
    setFieldTouched,
  } : {
    values: any,
    errors: FormikErrors<FormValues>,
    handleChange: any,
    handleReset: any,
    setFieldTouched: any,
    handleBlur: any,
    touched: FormikTouched<FormValues>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  } = useFormikContext<FormValues>();

  // console.log(name);
  // console.log(errors);

  return <>
    <Field
      name={name}

      title={title}
      label={label}
      placeholder={placeholder}
      caption={caption}
      clear={clear}
      composition={composition}
      icon={icon}
      units={units}
      action={action}
      validation={errors[name]}

      component={InputFC}
      disabled={disabled}
      className={className}
      {...props}
    />
  </>;
};


export const InputCVC = <FormValues, >({
  title,
  label,
  placeholder,
  caption,
  clear,
  composition,
  icon,
  units,
  error,
  status,
  disabled,
  name,
  className,
  autoComplete,
  action,
  ...props
} : FormGroupProps & Omit<InputProps, "name"> & {
  name: keyof FormValues
}) => {

  const {
    values,
    errors,
  } : {
    values: any,
    errors: FormikErrors<FormValues>,
  } = useFormikContext<FormValues>();

  const [value, setValue] = useState<number>(Number(values[name]));
  const regExp = /^[0-9]{1,3}$/;

  return <>
    <Field
      name={String(name)}

      title={title}
      label={label}
      placeholder={placeholder}
      caption={caption}
      clear={clear}
      composition={composition}
      icon={icon}
      units={units}
      action={action}
      status={status}
      autoComplete={autoComplete}
      validation={false}

      onChange={(e: React.ChangeEvent<any>) => {
        const event = e.nativeEvent as InputEvent;
        if (event.inputType === "insertText") {
          if (!(e.target.value.match(regExp) && e.target.value.match(regExp).length)) {
            e.currentTarget.value = value
          }
        }
        setValue(Number(e.currentTarget.value));
      }}
      component={InputFC}
      disabled={disabled}
      className={className}

      {...props}
    />
  </>;
};
