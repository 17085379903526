
export type Base64 = string;

export type Hex = string;

export type KeyPair = { public: Hex; secret: Hex };

export default class Utils {

    static truncAddress(address: string) {
        const parts = address.split(':');
        return `${parts[0]}:${parts[1].substr(0, 4)}`;
    }

    static base64ToBytesArray(base64: string) {
        return Buffer.from(base64, 'base64');
    }
    
    static bufferToHex(buffer: Buffer): Hex {
        return buffer.toString('hex');
    }
    
    static base64ToBuffer(base64: Base64): Buffer {
        return Buffer.from(base64, 'base64');
    }
    
    static hexToBuffer(hex: Hex): Buffer {
        return Buffer.from(hex, 'hex');
    }
    
    static hexToBase64(hex: Hex): Base64 {
        return Utils.hexToBuffer(hex).toString('base64');
    }
    
    static hexToArray(hex: Hex): Uint8Array {
        return new Uint8Array(Utils.hexToBuffer(hex).buffer);
    }
    
    static base64ToString(base64: Base64): string {
        return Utils.base64ToBuffer(base64).toString();
    }
    
    static hexToString(hex: Hex): string {
        return Utils.hexToBuffer(hex).toString();
    }
    
    static base64ToHex(base64: Base64): Hex {
        return Utils.bufferToHex(Buffer.from(base64, 'base64'));
    }
    
    static arrayToHex(array: Uint8Array) {
        return Utils.bufferToHex(Buffer.from(array));
    }
    
    static stringToHex(string: string): Hex {
        return Utils.bufferToHex(Buffer.from(string));
    }

    static getKBSize(base64: string) {
        return this.base64ToBytesArray(base64).length / 1024;
    }

    static divideBySubLists(list: any[], size: number = 50): any[][] {
        const result = [];
        for (let index = 0; index < list.length; index += size) {
            result.push(list.slice(index, index + size));
        }
        return result;
    }

    static bufferToBase64(buffer: Buffer) {
        return buffer.toString('base64');
    }

    static stringToBase64(string: string) {
        return this.bufferToBase64(Buffer.from(string));
    }

    static objectValues(object: { [key: string]: any }): any[] {
        return Object.keys(object || {}).map(field => object[field]);
    }

    static parseArray<T>(array: T[][] | { [key: string]: T }[] | any): T[] {
        return array.reduce((prev: T[], curr: T[] | { [key: string]: T }) => ([
            ...prev,
            ...(Array.isArray(curr) ? curr : this.objectValues(curr) || []),
        ]), []);
    }

    static groupById(list: any[], field: string) {
        return list.reduce((prev, curr) => ({
            ...prev,
            [curr[field]]: curr,
        }), {});
    }

    static callIfNotDuplicate(
        id: string,
        itemsById: { [key: string]: boolean },
        callback: (newItemsById: { [key: string]: boolean }) => void = () => {},
    ) {
        if (!itemsById[id]) {
            itemsById[id] = true;
            callback(itemsById);
        }
    }

    static removeDuplicates(list: (any | string)[], path?: string): (any | string)[] {
        const itemsById:any = {};
        list.forEach((item: any) => {
            const id = path ? item[path] : item;
            if (!itemsById[id]) {
                itemsById[id] = item;
            }
        });

        return this.objectValues(itemsById);
    }
}
