// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Nav, Tab, TabContainer } from "react-bootstrap";
import styles from "./Form.module.scss";
import classnames from "classnames/bind";
import { Panel, Icon, Input, InputNumber, Switch, Button } from "../../components";
import { useDashboard } from "../../controllers";
import { getConfigByDomain } from "../../client/client";
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Slider from '@mui/material/Slider';
import {ConnectButton} from '../../components/button';

import {getDecimalsCount, cutNumber, formatNumber, bnMultiply, bnLt, shiftedBy} from "../utils";

import {
  TradingPairInfo,
  exchangeMode,
  OrderBookRow
} from "../../interfaces";
import { isConstructorDeclaration } from "typescript";
import { act } from "react-dom/test-utils";

const enableMarketMode = getConfigByDomain().enableMarketMode;
const enableStopTab = false;

const cn = classnames.bind(styles);

export interface IFormProps {
  selectedPair?: TradingPairInfo<number> | undefined;
  authorized?: boolean;
  tradeLoading?: boolean;
  setExchangeMode?: any;
  handleShow?: any;
  trade?: any;
  selectedOrderBookRow?: OrderBookRow;
  exchangeMode?: exchangeMode;
  walletsInfo?: any;
}

interface FormValues {
  total: string;
  price: string;
  stop: number;
  amount: string;
  post: boolean;
  ioc: boolean;
}

export const Form = ({
  selectedPair,
  selectedOrderBookRow,
  authorized,
  exchangeMode,
  setExchangeMode,
  handleShow,
  tradeLoading,
  trade,
  walletsInfo
}: IFormProps) => {
  const [ formMode, setFormMode ] = useState<string>("limit");
  const [ marketPrice, setMarketPrice ] = useState<string>('0');
  const [ update, setUpdate ] = useState<boolean>(false);
  const [ sliderValue, setSliderValue ] = useState<number>(0);

  const {
    pairInfo: {data: pairInfo},
  } = useSelector((state: RootState) => state.flex);

  const pairPriceDecimalsCount = selectedPair ? getDecimalsCount(selectedPair.minMove / selectedPair.priceScale) : 0;
  const pairAmountDecimalsCount = selectedPair ? getDecimalsCount(selectedPair.minAmount) : 0;
  const pairTotalDecimalsCount = selectedPair ? selectedPair.minorDecimals : 0;

  const getWalletBalance = () => {
    if (!selectedPair) return undefined;
    if (!walletsInfo || !walletsInfo.length) return undefined;

    let wallet = null;
    if (exchangeMode === 'sell') wallet = walletsInfo.find((w: any) => w.ticker === selectedPair.major);
    if (exchangeMode === 'buy') wallet = walletsInfo.find((w: any) => w.ticker === selectedPair.minor);
    if (wallet) return wallet.availableBalance;
    return undefined;
  }

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .test(
        'is-decimal',
        'invalid decimal',
        value => value ? getDecimalsCount(value) <= pairPriceDecimalsCount : true
      )
      .typeError('This one should be a number')
      .required(''),
    amount: Yup.number()
      .test(
        'is-decimal',
        'invalid decimal',
        value => value ? getDecimalsCount(value) <= pairAmountDecimalsCount : true
      )
      .typeError('This one should be a number')
      .required('')
  });

  const Formik = useFormik({
    initialValues: {
      total: '0',
      price: '0',
      stop: 0,
      amount: '0',
      post: false,
      ioc: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({}, { setSubmitting }) => {
      const strTotal = bnMultiply(getPrice(), Formik.values.amount);
      Formik.setFieldValue("total", formatNumber(strTotal));
      const strPriceNum = bnMultiply(getPrice(), selectedPair.priceScale);
      const strAmount = shiftedBy(Formik.values.amount, selectedPair.majorDecimals);
      if (formMode === 'limit') {
        trade({
          sell: exchangeMode === "sell",
          price: strPriceNum,
          amount: strAmount,
          ioc: Formik.values.ioc,
          post: Formik.values.post,
          tradeMode: formMode,
        }, () => {
          Formik.setFieldValue("price", '0');
          Formik.setFieldValue("amount", '0');
          setSliderValue(0);
        });
      }
      if (formMode === 'market') {
        trade({
          sell: exchangeMode === "sell",
          amount: strAmount,
          tradeMode: formMode,
        }, () => {
          Formik.setFieldValue("amount", '0');
          setSliderValue(0);
        });
      }
    }
  });

  const getPrice = () => {
    if (formMode === 'limit') return Formik.values.price;
    if (formMode === 'market') return marketPrice;
    return '0';
  }

  const updateTotal = () => {
    if (!selectedPair) return;
    const strTotal = bnMultiply(getPrice(), Formik.values.amount);
    Formik.setFieldValue("total", formatNumber(strTotal));
  }

  useEffect(()=>{
    const marketPriceStr = pairInfo ? cutNumber(pairInfo.price, pairPriceDecimalsCount) : '0';
    setMarketPrice(marketPriceStr);
  }, [pairInfo]);

  useEffect(() => {
    if (selectedOrderBookRow && selectedPair) {
      const orderPriceStr = cutNumber(selectedOrderBookRow.price, pairPriceDecimalsCount);
      const orderAmountStr = cutNumber(selectedOrderBookRow.sellVolume || selectedOrderBookRow.buyVolume, pairAmountDecimalsCount);
      if (formMode === 'limit') Formik.setFieldValue("price", orderPriceStr);
      Formik.setFieldValue("amount", orderAmountStr);
      updateTotal();
      setUpdate(!update);
    }
  }, [selectedOrderBookRow]);

  useEffect(() => {
    if (Formik.values.post) {
      Formik.values.ioc = false;
      setUpdate(!update);
    }
  }, [Formik.values.post]);

  useEffect(() => {
    if (Formik.values.ioc) {
      Formik.values.post = false;
      setUpdate(!update);
    }
  }, [Formik.values.ioc]);

  useEffect(() => {
    updateTotal();
    checkSlider();
  }, [Formik.values.price, Formik.values.amount, marketPrice, formMode]);

  const checkSlider = () => {
    if (Formik.values.amount !== sliderToAmount()) setSliderValue(0);
  }

  const sliderToAmount = () => {
    if (!selectedPair) return undefined;
    if (sliderValue === 0) {
      return Formik.values.amount;
    }
    if (parseFloat(getPrice()) === 0 && exchangeMode === 'buy') {
      return '0';
    }
    /*
    1. SELL order: Amount = x% * available_major_tokens;
    2. BUY order: Amount = x% * available_minor_tokens / price
    3. When the slider is in the position of 100% - the amount of available_tokens used for trade should be reduced by the commission required for Taker (X = FullValue / 1.001).
    */
    const availableBalance = getWalletBalance();
    if (availableBalance === undefined) {
      return '0';
    }

    if (exchangeMode === 'sell') {
      let available = sliderValue / 100.0 * availableBalance;
      if (sliderValue === 100) available /= getConfigByDomain().fee;

      //console.log(`useEffect sliderValue ${sliderValue}, availableBalance=${availableBalance}, available=${available}. pairAmountDecimalsCount=${pairAmountDecimalsCount}`);
      available = Math.trunc(available * (10**pairAmountDecimalsCount))/(10**pairAmountDecimalsCount);
      const amountStr = cutNumber(available, pairAmountDecimalsCount);
      //console.log(`available=${amountStr}`);
      return amountStr;
    }

    if (exchangeMode === 'buy') {
      let available = sliderValue / 100.0 * availableBalance / parseFloat(getPrice());
      if (sliderValue === 100) available /= getConfigByDomain().fee;
      available = Math.trunc(available * (10**pairAmountDecimalsCount))/(10**pairAmountDecimalsCount);
      const amountStr = cutNumber(available, pairAmountDecimalsCount);
      //console.log(`available=${amountStr}`);
      return amountStr;
    }

    return undefined;
  }

  useEffect(()=>{
    const strAmount = sliderToAmount();
    if (strAmount === undefined) return;
    Formik.setFieldValue("amount", strAmount);
  }, [sliderValue, exchangeMode])

  useEffect(() => {
    Formik.setFieldValue("amount", '0');
    if (formMode === 'limit') {
      Formik.setFieldValue("price", '0');
    }
  }, [selectedPair])

  return (
    <Tab.Container
      defaultActiveKey={"limit"}
      onSelect={(activeKey) => {
        setFormMode(String(activeKey).toLowerCase());
      }}
    >
      <Panel
        header={<Nav variant="pills" className={cn("head-narrow head-narrow-note", "nav-pills")}
        onSelect={(activeKey) => {
          setFormMode(String(activeKey).toLowerCase());
        }}>
          <Nav.Item>
            <Nav.Link className={cn("nav-link-pill")} eventKey="limit">Limit</Nav.Link>
          </Nav.Item>
          {
            enableMarketMode &&
            <Nav.Item>
              <Nav.Link className={cn("nav-link-pill")} eventKey="market">Market</Nav.Link>
            </Nav.Item>
          }
          {
            enableStopTab &&
            <Nav.Item>
              <Nav.Link disabled={true} className={cn("nav-link-pill")} eventKey="stop">Stop</Nav.Link>
            </Nav.Item>
          }
        </Nav>}
      >
      <FormikProvider value={Formik}>
        <form onSubmit={Formik.handleSubmit}>
          <Tab.Content>
            <Tab.Pane eventKey="limit">
              <Nav activeKey={exchangeMode} variant="pills" className={cn("tab-switcher")}
                onSelect={(activeKey) => {
                  //console.log(activeKey);
                  setExchangeMode(String(activeKey).toLowerCase());
                }}>
                <Nav.Item>
                  <Nav.Link className={cn("green act act-callout")} eventKey="buy" onClick={() => setExchangeMode("buy")}>Buy</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className={cn("red act act-callout")} eventKey="sell" onClick={() => setExchangeMode("sell")}>Sell</Nav.Link>
                </Nav.Item>
              </Nav>
              <InputNumber<FormValues>
                className={cn("form-input")}
                valueTester={(value: any )=>{
                  if (!selectedPair) return true;
                  if (getDecimalsCount(value) <= pairPriceDecimalsCount) return value; //new value
                  return Formik.values.price; //prev value
                }}
                composition={"substitute"}
                name={"price"}
                title={"Price"}
                placeholder={"0"}
                units={selectedPair ? selectedPair.minor : <>&mdash;</>}
              />
              <InputNumber<FormValues>
                className={cn("form-input")}
                valueTester={(value: any )=>{
                  if (!selectedPair) return true;
                  if (getDecimalsCount(value) <= pairAmountDecimalsCount) return value; //new value
                  return Formik.values.amount; //prev value
                }}
                composition={"substitute"}
                name={"amount"}
                title={"Amount"}
                placeholder={"0"}
                units={selectedPair ? selectedPair.major : <>&mdash;</>}
              />
              {true && <Slider
                sx={{
                  '& .MuiSlider-markActive': {
                    backgroundColor: 'currentColor !important',
                    opacity: '1 !important',
                  },
                  '& .MuiSlider-mark': {
                    //width: '0px',
                    //height: '0px',
                  },
                  '& .MuiSlider-mark:nth-of-type(5n+3)': {
                    borderRadius: '1px',
                    //width: '15px',
                    height: '15px',
                  },
                  '& .MuiSlider-thumb': {
                    //borderRadius: '8px',
                    width: '15px',
                    height: '15px',
                  }
                }}
                valueLabelFormat={(value: number) => {
                  return `${value}%`;
                }}
                marks
                valueLabelDisplay="auto"
                value={sliderValue}
                defaultValue={0}
                step={5}
                min={0}
                max={100}
                onChange={(ev)=>{
                  //console.log('slider onChange event: ', ev);
                  const target: any = ev ? ev.target : null;
                  target && setSliderValue(target.value);
                }}
                disabled={!Formik.values.price || parseFloat(Formik.values.price) === 0 || getWalletBalance() === undefined}
              />}

              <InputNumber<FormValues>
                className={cn("form-input")}
                composition={"substitute"}
                name={"total"}
                title={"Total"}
                placeholder={"0"}
                units={selectedPair ? selectedPair.minor : <>&mdash;</>}
                disabled
              />
              <div className="checkboxes">
                <Switch<FormValues>
                  name={"post"}
                  label={"POST"}
                  value={Formik.values.post}
                  onChange={Formik.handleChange}
                />
                <Switch<FormValues>
                  name={"ioc"}
                  label={"IOC"}
                  onChange={Formik.handleChange}
                  value={Formik.values.ioc}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="market">
              <Nav activeKey={exchangeMode} variant="pills" className={cn("tab-switcher")}
                onSelect={(activeKey) => {
                  //console.log(activeKey);
                  setExchangeMode(String(activeKey).toLowerCase());
                }}>
                <Nav.Item>
                  <Nav.Link className={cn("green act act-callout")} eventKey="buy" onClick={() => setExchangeMode("buy")}>Buy</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className={cn("red act act-callout")} eventKey="sell" onClick={() => setExchangeMode("sell")}>Sell</Nav.Link>
                </Nav.Item>
              </Nav>
              <InputNumber<FormValues>
                className={cn("form-input")}
                composition={"substitute"}
                name={"priceMarket"}
                title={"Price"}
                placeholder={"Market"}
                disabled
                units={selectedPair ? selectedPair.minor : <>&mdash;</>}
              />
              <InputNumber<FormValues>
                className={cn("form-input")}
                valueTester={(value: any )=>{
                  if (!selectedPair) return true;
                  if (getDecimalsCount(value) <= pairAmountDecimalsCount) return value; //new value
                  return Formik.values.amount; //prev value
                }}
                composition={"substitute"}
                name={"amount"}
                title={"Amount"}
                placeholder={"0"}
                units={selectedPair ? selectedPair.major : <>&mdash;</>}
              />
              {true && <Slider
                sx={{
                  '& .MuiSlider-markActive': {
                    backgroundColor: 'currentColor !important',
                    opacity: '1 !important',
                  },
                  '& .MuiSlider-mark': {
                    //width: '0px',
                    //height: '0px',
                  },
                  '& .MuiSlider-mark:nth-of-type(5n+3)': {
                    borderRadius: '1px',
                    //width: '15px',
                    height: '15px',
                  },
                  '& .MuiSlider-thumb': {
                    //borderRadius: '8px',
                    width: '15px',
                    height: '15px',
                  }
                }}
                valueLabelFormat={(value: number) => {
                  return `${value}%`;
                }}
                marks
                valueLabelDisplay="auto"
                value={sliderValue}
                defaultValue={0}
                step={5}
                min={0}
                max={100}
                onChange={(ev)=>{
                  //console.log('slider onChange event: ', ev);
                  const target: any = ev ? ev.target : null;
                  target && setSliderValue(target.value);
                }}
                disabled={!marketPrice || parseFloat(marketPrice) === 0 || getWalletBalance() === undefined}
              />}

              <InputNumber<FormValues>
                className={cn("form-input")}
                composition={"substitute"}
                name={"total"}
                title={"Total"}
                placeholder={"0"}
                units={selectedPair ? selectedPair.minor : <>&mdash;</>}
                disabled
              />
            </Tab.Pane>
          <Tab.Pane eventKey="stop">

                <Nav activeKey={exchangeMode} variant="pills" className={cn("tab-switcher")}
                  onSelect={(activeKey) => {
                    //console.log(activeKey);
                    setExchangeMode(String(activeKey).toLowerCase());
                  }}>
                  <Nav.Item>
                    <Nav.Link className={cn("green act act-callout")} eventKey="buy" onClick={() => setExchangeMode("buy")}>Buy</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className={cn("red act act-callout")} eventKey="sell" onClick={() => setExchangeMode("sell")}>Sell</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Input<FormValues>
                  name={"stop"}
                  label={"Stop"}
                  placeholder={"Market"}
                  disabled={true}
                  value={undefined}
                  onChange={Formik.handleChange}
                  units={selectedPair ? selectedPair.minor : <>&mdash;</>}
                />
                <Input<FormValues>
                  name={"price"}
                  label={"Limit"}
                  value={Formik.values.price}
                  onChange={Formik.handleChange}
                  units={selectedPair ? selectedPair.minor : <>&mdash;</>}
                />
                <Input<FormValues>
                  name={"amount"}
                  label={"Amount"}
                  placeholder={"Amount"}
                  value={Formik.values.amount}
                  onChange={Formik.handleChange}
                  units={selectedPair ? selectedPair.major : <>&mdash;</>}
                />
                <div className="checkboxes">
                  <Switch<FormValues>
                    name={"stop-post"}
                    label={"POST"}
                    value={Formik.values.post}
                    onChange={Formik.handleChange}
                  />
                  <Switch<FormValues>
                    name={"stop-ioc"}
                    label={"IOC"}
                    onChange={Formik.handleChange}
                    value={Formik.values.ioc}
                  />
                </div>

          </Tab.Pane>
        </Tab.Content>
            {authorized
              ? <Button
                  variant="primary"
                  disabled={
                    !Boolean(parseFloat(getPrice()))
                    || !Boolean(parseFloat(Formik.values.amount))
                    || tradeLoading
                    || getWalletBalance() === undefined
                    || exchangeMode === 'buy' && bnLt(getWalletBalance(), bnMultiply(getPrice(), Formik.values.amount))
                    || exchangeMode === 'sell' && bnLt(getWalletBalance(), Formik.values.amount)
                  }
                  className={cn({"btn-buy": exchangeMode === 'buy'}, {"btn-sell": exchangeMode === 'sell'})}
                  onClick={(e) => Formik.handleSubmit()}
                >
                  {exchangeMode === 'buy' ? "Buy" : "Sell" } {selectedPair ? (Formik.values.amount !== '0' ? formatNumber(Formik.values.amount) : '') + ' ' + selectedPair.major : '-'}
                </Button>
              : <ConnectButton authorized={authorized} onClick={handleShow} />
            }


          </form>
        </FormikProvider>
      </Panel>
    </Tab.Container>
  );
}

export default Form;
