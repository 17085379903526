import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "./Orderbook.module.scss";
import classnames from "classnames/bind";
import { Panel, Icon, Skeleton } from "../../components";
import { useOrderbook } from "../../controllers";

import { TradingPairInfo } from "../../interfaces";

import {getDecimalsCount, formatNumber} from "../utils";

const cn = classnames.bind(styles);

export interface IOrderbookProps {
  selectedPair?: TradingPairInfo<number> | undefined;
  sell?: boolean | undefined;
}

const OrderbookRow = ({
  color,
  price,
  volume,
  onClick
}: {
  color: string,
  price: string,
  volume: string,
  onClick: () => void,
  max: number
}) => {
  return (
    <tr onClick={onClick}>
      <td className={cn(color)}>{price}</td>
      <td className="right">{volume}</td>
    </tr>
  );
}

const TableHeader = ({selectedPair}: IOrderbookProps) => {
  return (
    <Table>
      <thead>
        <tr>
          <th className="paragraph paragraph-footnote">{selectedPair ? <>Price, {selectedPair.ticker.split("/")[1]}</> : <Skeleton wide="half" />}</th>
          <th className="paragraph paragraph-footnote right">{selectedPair ? <>Amount, {selectedPair.ticker.split("/")[0]}</> : <Skeleton wide="half" />}</th>
        </tr>
      </thead>
    </Table>
  );
}

const TableOrder = ({selectedPair, sell}: IOrderbookProps) => {
  const { orderbook, orderbookMax, selectOrder } = useOrderbook(selectedPair);

  return (
    <Table>
      <tbody className={cn("mono-narrow mono-narrow-note", "orderbook")}>
          {selectedPair && orderbook && orderbookMax && orderbook.length
            ? <>{orderbook.filter(row => sell ? row.sellVolume : row.buyVolume).map((row, index) =>
                <OrderbookRow
                  key={index}
                  onClick={() => selectOrder(row)}
                  color={sell ? "red" : "green"}
                  price={formatNumber(row.price, getDecimalsCount(selectedPair.minMove/selectedPair.priceScale))}
                  volume={formatNumber(sell ? row.sellVolume : row.buyVolume, getDecimalsCount(selectedPair.minAmount))}
                  max={1}
                />)}</>
            : <>{Array(4).fill(null).map((el, index) => <tr key={index}>
                  <td><Skeleton variant="primary" /></td>
                  <td className="right"><Skeleton /></td>
                </tr>)
              }</>
          }
      </tbody>
    </Table>
  );
}

const TableBalanced = ({selectedPair}: IOrderbookProps) => {
  const { orderbook, trades } = useOrderbook(selectedPair);

  let lastTradePrice  = null;
  let deltaTrade = 0;
  let deltaTradeStr = '';
  let percentStr = '';
  if (selectedPair && orderbook && orderbook.length && trades && trades.length) {
    const decimals = getDecimalsCount(selectedPair.minMove/selectedPair.priceScale);
    lastTradePrice = formatNumber(trades[0].price, decimals);
    if (trades.length > 1) {
      const tradeLastPrice = parseFloat(trades[0].price);
      const tradePreLastPrice = parseFloat(trades[1].price);
      deltaTrade = tradeLastPrice - tradePreLastPrice;
      const percent = deltaTrade * 100 / tradePreLastPrice;
      percentStr = formatNumber(percent, 2);
      deltaTradeStr = formatNumber(deltaTrade, decimals);
    }
  }

  if (!orderbook?.length || !trades?.length) return (
    <Table>
      <tr>
        <td colSpan={2}>
          <div className="balanced-value">
            <Skeleton variant="green"/>
            <Skeleton wide="triplet"/>
            <Skeleton wide="triplet"/>
          </div>
        </td>
      </tr>
    </Table>
  );

  return (
    <Table>
      <tr>
        <td colSpan={2}>
          <div className="balanced-value">
            {trades.length > 1
            ? <>
                <div className={`value headline headline-head ${deltaTrade >= 0 ? "green" : "red"}`}>
                  {lastTradePrice}
                  {deltaTrade >= 0 ?
                    <Icon icon="caret-up"/> :
                    <Icon icon="caret-down"/>
                  }</div>
                <span className="paragraph paragraph-footnote null">
                  {percentStr}&thinsp;%</span>
                <span className="paragraph paragraph-footnote null">
                  {deltaTradeStr}
                </span>
              </>
            : <>
                <div className={`value headline headline-head green`}>
                  {lastTradePrice}
                </div>
              </>
            }
          </div>
        </td>
      </tr>
    </Table>
  );
}

export const Orderbook = ({selectedPair}: IOrderbookProps) => {
  const { orderbook } = useOrderbook(selectedPair);
  console.log('orderbook=>', orderbook);

  useEffect(() => {
    const element = document.getElementById('container-sell');
    if (element) element.scrollTop = 100500;
  },[orderbook]);

  return (
    <Panel header={"Orderbook"}>
      <TableHeader selectedPair={selectedPair}/>
      <div id="container-sell" className={cn("scrollable-table-container")} style={{maxHeight: 190}}>
        <TableOrder sell selectedPair={selectedPair} />
      </div>
      <TableBalanced selectedPair={selectedPair} />
      <div className={cn("scrollable-table-container")} style={{maxHeight: 190}}>
        <TableOrder sell={false} selectedPair={selectedPair} />
      </div>
    </Panel>
  );
}

export default Orderbook;
