import { v4 as uuidv4 } from 'uuid';

import DebotClient from "../client/client";
import { ElectronUtils } from '../client/ElectronUtils';

const measurement_id = "G-FL5TGJC8G9";
const api_secret = "ZqWcDkFzRsya_ktIJ_v9Ng";
const endpoint = "https://www.google-analytics.com/mp/collect";
const client_id = uuidv4();

const GA = {};

GA.sendAuthorized = (userId) => {
  if (!ElectronUtils.isElectron) return;

  fetch(`${endpoint}?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id,
      events: [{
        name: 'electron_authorized',
        params: {
          user_id: `=${userId}`,
        },
      }]
    })
  });
}

GA.sendTrade = (tradeType, userId, tradingPair) => {
  if (!ElectronUtils.isElectron) return;

  fetch(`${endpoint}?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id,
      events: [{
        name: 'electron_trade',
        params: {
          type: tradeType,
          user_id: `=${userId}`,
          pair: tradingPair,
        },
      }]
    })
  });
}

GA.sendStarted = () => {
  if (!ElectronUtils.isElectron) return;

  fetch(`${endpoint}?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
    method: "POST",
    body: JSON.stringify({
      client_id,
      events: [{
        name: 'electron_started',
        params: {
        },
      }]
    })
  });
}

export default GA;
