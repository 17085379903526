import React, { useRef, useEffect } from "react";
import { Nav } from "react-bootstrap";
import classnames from "classnames/bind";

import { Button, Icon } from "../../components";
import { gotoDownloads, getConfigByDomain } from "../../client/client";
import styles from "../header/Header.module.scss";

const cn = classnames.bind(styles);
const DOWNLOADS_TEXT = 'Download Flex Dapp';

export const DownloadsButton = ({navlink}: {navlink?: boolean}) => {
  if (navlink)
    return (
      <Nav.Link
        className={cn("nav-link", "surf-toggle")}
        onClick={gotoDownloads}
      >
        <><Icon icon={"download"} />{DOWNLOADS_TEXT}</>
      </Nav.Link>
    );
  return (
    <div className={"not-connected"}>
      <Button variant="semitransparent" className={"connect"} onClick={gotoDownloads}>
        <Icon icon={"download"} />{DOWNLOADS_TEXT}
      </Button>
    </div>
  );
};

export const ConnectButton = ({authorized, onClick, navlink}: {authorized?: boolean, onClick?: any, navlink?: boolean}) => {
  if (getConfigByDomain().authDisabled) return <DownloadsButton />;
  if (authorized) return null;
  return (
    <div className={"not-connected"}>
      <Button variant="semitransparent" className={"connect"} onClick={onClick}>
        <Icon icon={"connect"} />Connect Surf
      </Button>
    </div>
  );
}
