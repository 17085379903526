import callback from "./abi";

import {
  Debot,
  address,
  TradeStatus,
 } from "../interfaces";

 import GA from "../ga";

 import { ElectronUtils } from './ElectronUtils';

//import * as browser from "debot-browser";
const browser = import("debot-browser");

export const chartsUrl = 'https://flex-charting.web.app';

const DEX_TON_SURF = 'dex.ton.surf'; //test
const TRADE_FLEXDEX_FI = 'trade.flexdex.fi'; //trade
const STAGE_FLEXDEX_FI = 'stage.flexdex.fi'; //stage
const FLEX_TON_LIVE = 'flex.ton.live'; //test
const ELECTRON = 'ELECTRON'; //ELECTRON

const configs = {
  [ELECTRON]: {
    endpoint: 'mainnet.evercloud.dev/9032ecfe98924075ae833da11735b480',//'prod.flex.everos.dev',
    authDebot: '0:d0568a66f2e67e16fc2ae49f21b109f7116f484ae401fc93f640a232ddea329f',
    tradeDebot: '0:1d875fefaaca191972013a3221ccb266499e5feb4355c24a5eaad7e75ad1aa7e',
    alias: 'mainnet',
    fee: 1.0015,
    enableMarketMode: true,
    enableCustomKeys: true,
    pairsDisabled: ['USDC/USDT'],
    authDisabled: false,
    defaultPairTicker: "EVER/USDT",
  },
  [DEX_TON_SURF]: {
    endpoint: 'test.flex.everos.dev',
    authDebot: '0:06b464399b9482cced00b780e8649618d62608a32f74a5626e325d5f6f49b121',//'0:8e2bdf04b3ff27d2aa2982863d140203f7bae0fc3bd520b68754e8d9445a8b9c',
    tradeDebot: '0:85cb0036a95122adfaaa21175759dac7e412b208f4127e49eaa09d9a6b7e4316',// '0:83eed6e726eb4c34d5cea961d061967a20721a2528565cb113a874ffb0d98605',
    alias: 'devnet',
    fee: 1.0015,
    enableMarketMode: true,
    enableCustomKeys: true,
    pairsDisabled: ['USDC/USDT'],
    authDisabled: false,
    defaultPairTicker: "EVER/TSDT",
  },
  [TRADE_FLEXDEX_FI]: { //ELECTRON MIRROR with NO AUTH
    endpoint: 'mainnet.evercloud.dev/9032ecfe98924075ae833da11735b480',
    authDebot: '0:d0568a66f2e67e16fc2ae49f21b109f7116f484ae401fc93f640a232ddea329f',
    tradeDebot: '0:1d875fefaaca191972013a3221ccb266499e5feb4355c24a5eaad7e75ad1aa7e',
    alias: 'mainnet',
    fee: 1.0015,
    enableMarketMode: true,
    enableCustomKeys: true,
    pairsDisabled: ['USDC/USDT'],
    authDisabled: true,
    defaultPairTicker: "EVER/USDT",
  },
  [STAGE_FLEXDEX_FI]: { //EX-TRADE
    endpoint: 'devnet.evercloud.dev/9032ecfe98924075ae833da11735b480',
    authDebot: '0:8e2bdf04b3ff27d2aa2982863d140203f7bae0fc3bd520b68754e8d9445a8b9c',
    tradeDebot: '0:83eed6e726eb4c34d5cea961d061967a20721a2528565cb113a874ffb0d98605',
    alias: 'devnet',
    fee: 1.0015,
    enableMarketMode: true,
    enableCustomKeys: true,
    pairsDisabled: ['USDC/USDT'],
    authDisabled: false,
    defaultPairTicker: "EVER/USDT",
  }
}

export const gotoDownloads = () => {
  window.open(DOWNLOADS_URL, '_blank');
}

export const getConfigByDomain = () => {
  if (ElectronUtils.isElectron) {
    return configs[ELECTRON];
  }
  const path = window.location.origin;
  if (path.includes(FLEX_TON_LIVE)) return configs[STAGE_FLEXDEX_FI];
  if (path.includes(STAGE_FLEXDEX_FI)) return configs[STAGE_FLEXDEX_FI];
  if (path.includes(TRADE_FLEXDEX_FI)) return configs[TRADE_FLEXDEX_FI];
  if (path.includes(DEX_TON_SURF)) return configs[DEX_TON_SURF];
  return configs[DEX_TON_SURF];
}

const DOWNLOADS_URL = 'https://flexdex.fi/downloads/';
export const ENDPOINT = getConfigByDomain().endpoint;

export const Debots:Map<string, Debot> = new Map([
  ['auth', {
    title: 'auth',
    name: ENDPOINT,
    alias: getConfigByDomain().alias,
    address: getConfigByDomain().authDebot,
  }],
  ['trade', {
    title: 'trade',
    name: ENDPOINT,
    alias: getConfigByDomain().alias,
    address: getConfigByDomain().tradeDebot,
  }],
]);

const logger = console;

export default class DebotClient {
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  static hexToUtf8 = DebotClient.convert('hex', 'utf8');
  static utf8ToHex = DebotClient.convert('utf8', 'hex');

  private static apiBrowserHandle: Promise<BigInt>;
  private static tradeBrowserHandle: Promise<BigInt>;
  private static sboxApiHandle: number; // or string with public key
  private static sboxTradeHandle: number; // or string with public key

  private static defaultWallet: any = null; // or string with TON address
  private static defaultPubkey: any = null; // or string with public key


  static flexClient: string; // or string with public key
  static userId: string; // or string with public key


  private static keypair: {
    public: string,
    private: string,
  };

  static userSigningBox: any = {}; // or string with public key

  constructor () {
    DebotClient.init();
  }

  static async init () {
    console.log("init");
    //DebotClient.apiBrowserHandle = (await browser).create_browser(Debots.get('api')!.name, Debots.get('api')!.address, DebotClient.defaultWallet, DebotClient.defaultPubkey);
    DebotClient.tradeBrowserHandle = (await browser).create_browser(Debots.get('trade')!.name, Debots.get('trade')!.address);
  }

  /**
   * Update client with signing box
   * TODO proper typization for keypair prop
   **/
  static async updateApiBrowser (
    keypair: any
  ): Promise<void[]> {
    DebotClient.keypair = keypair;

    DebotClient.userSigningBox.get_public_key = async () => {
      return DebotClient.keypair.public
    }

    DebotClient.userSigningBox.sign = async (unsigned: any) => {
      const res = await (await browser).sign(DebotClient.keypair, unsigned)
      return res.signature
    }

    //DebotClient.sboxApiHandle = await (await browser).register_signing_box((await DebotClient.apiBrowserHandle), DebotClient.userSigningBox)
    DebotClient.sboxTradeHandle = await (await browser).register_signing_box((await DebotClient.tradeBrowserHandle), DebotClient.userSigningBox)

    return [
      /*await (await browser).update_user_settings((await DebotClient.apiBrowserHandle), {
        wallet: DebotClient.defaultWallet,
        pubkey: DebotClient.keypair.public,
        signing_box: DebotClient.sboxApiHandle
      }),*/
      await (await browser).update_user_settings((await DebotClient.tradeBrowserHandle), {
        wallet: DebotClient.defaultWallet,
        pubkey: DebotClient.keypair.public,
        signing_box: DebotClient.sboxTradeHandle
      })
    ];
  }

  /**
   * Get JSON manifest for browser request
   * TODO proper typization for func and args prop
   **/
  private static buildTradeManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('trade')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "autoApprove": ["ApproveOnChainCall"],
      "chain": [],
      "abi": ${callback.tradeabi}
    }`);
  };

  /**
   * TRADE DEBOT
   **/

   static async marketOrder(
     tradingPair: address,
     sell: boolean,
     amount: string,
   ): Promise<TradeStatus> {
     logger.log(`Calling trade for ${tradingPair}...\n`);
     if (sell) {GA.sendTrade('sell', DebotClient.userId, tradingPair);}
     else {GA.sendTrade('buy', DebotClient.userId, tradingPair);}

     const manifest = DebotClient.buildTradeManifest("marketOrder", `{"pubkey": "0x${await DebotClient.keypair.public}", "flexClient": "${DebotClient.flexClient}", "userId": "${DebotClient.userId}", "tradingPair": "${tradingPair}", "sell": "${sell}", "amount": "${amount}"}`)
     console.log(manifest)
     const tradeResult = await (await browser).run_browser((await DebotClient.tradeBrowserHandle), manifest);
     DebotClient.autoTopup(tradingPair); //call after every trade
     return tradeResult;
   }

  static async limitOrder(
    tradingPair: address,
    sell: boolean,
    ioc: boolean,
    post: boolean,
    amount: string,
    priceNum: string
  ): Promise<TradeStatus> {
    logger.log(`Calling trade for ${tradingPair}...\n`);
    if (sell) {GA.sendTrade('sell', DebotClient.userId, tradingPair);}
    else {GA.sendTrade('buy', DebotClient.userId, tradingPair);}

    const manifest = DebotClient.buildTradeManifest("limitOrder", `{"pubkey": "0x${await DebotClient.keypair.public}", "flexClient": "${DebotClient.flexClient}", "userId": "${DebotClient.userId}", "tradingPair": "${tradingPair}", "sell": "${sell}", "ioc": "${ioc}", "post": "${post}", "amount": "${amount}", "priceNum": "${priceNum}"}`)
    console.log(manifest)
    const tradeResult = await (await browser).run_browser((await DebotClient.tradeBrowserHandle), manifest);
    DebotClient.autoTopup(tradingPair); //call after every trade
    return tradeResult;
  }

  static async autoTopup(
    tradingPair: address,
  ): Promise<TradeStatus> {
    logger.log(`Calling trade for ${tradingPair}...\n`);
    const manifest = DebotClient.buildTradeManifest("autoTopup", `{"pubkey": "0x${await DebotClient.keypair.public}", "flexClient": "${DebotClient.flexClient}", "userId": "${DebotClient.userId}", "tradingPair": "${tradingPair}"}`)
    console.log(manifest)
    return await (await browser).run_browser((await DebotClient.tradeBrowserHandle), manifest);
  }

  static async cancelOrder(
    tradingPair: address,
    sell: boolean,
    price: string,
    orderId: string,
  ): Promise<TradeStatus> {
    logger.log(`Calling cancelOrder for ${tradingPair}...\n`);
    const manifest = DebotClient.buildTradeManifest("cancelOrder", `{"flexClient": "${DebotClient.flexClient}", "userId": "${DebotClient.userId}", "tradingPair": "${tradingPair}", "sell": "${sell}", "priceNum": "${price}", "orderId": "${orderId}"}`)
    console.log(manifest)
    const tradeResult = await (await browser).run_browser((await DebotClient.tradeBrowserHandle), manifest);
    DebotClient.autoTopup(tradingPair); //call after every cancelOrder
    return tradeResult;
  }

  static async getSBox(): Promise<any> {
    logger.log(`Calling getSBox`);
    const manifest = DebotClient.buildTradeManifest("getSBox", `{}`)
    return await (await browser).run_browser((await DebotClient.tradeBrowserHandle), manifest);
  }
}

(async () => {
  new DebotClient();
})()
