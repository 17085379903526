import callback from "./abi";

import DebotClient, { Debots } from "./client";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
//import { Cookies } from "react-cookie";
import Utils from "./utils";
import { ElectronUtils } from './ElectronUtils';

const browser = import("debot-browser");

const logger = console;

export type Base64 = string;

export type Hex = string;

export type KeyPair = { public: Hex; secret: Hex };

export type EncryptedString = {
  encStr: Base64;
  nonce: Base64;
};

const nonceLength = 12;

export default class Poochy {
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  static hexToUtf8 = Poochy.convert('hex', 'utf8');
  static utf8ToHex = Poochy.convert('utf8', 'hex');

  private static authBrowserHandle: Promise<BigInt>;

  private static _isAuthenticated: any = false;
  private static _isEntered: boolean = false;

  private static keystoreEncrypted: string;
  private static nonceEncrypted: string;

  private static derivedKey: Hex;

  static mode: "signin" | "signup";

  // Update this to Electron device id provision
  private static deviceID: Promise<string> = (
    async () => {
      //return ((await (await FingerprintJS.load()).get()).visitorId)
      if (ElectronUtils.isElectron) {
        // Get an unique device ID for Electron-based desktop application
        return (await ElectronUtils.requestDeviceId());
      }
      return ((await (await FingerprintJS.load()).get()).visitorId);
    }
  )()

  private static keystore: {
    keyPair: {
      secret: string,
      public: string
    } | undefined,
    version: string
  } = {
    keyPair: undefined,
    version: "1.0",
  }
  private static enter: string = "t8mK_1";
  private static username: string;
  private static password: string;
  private static userId: string = "";
  private static flexClient: string = "";
  private static Storage: any;

  private static PollingInterval: ReturnType<typeof setTimeout>;

  static authMessage: string | undefined = undefined;

  constructor () {
    Poochy.init();
    // ruff
    // woof
    // check if there's a keypair
    // generate one or decode one
    // call auth debot with getFlexClient and check returning value whether it's null or not
    // NOT NULL you are authorized - call api debot update user settings to sign their requests
    // NULL you are not authorized - generate call auth debot getInvokeAuthMessage
  }

  static async init () {
    Poochy.Storage = localStorage;//new Cookies();

    if (Poochy.Storage.getItem("woof") && Poochy.Storage.getItem("ruff")) {
      Poochy.mode = "signin";
      Poochy.keystoreEncrypted = Poochy.Storage.getItem("woof");
      Poochy.nonceEncrypted = Poochy.Storage.getItem("ruff");
    } else {
      Poochy.mode = "signup";
      Poochy.Storage.removeItem("woof");
      Poochy.Storage.removeItem("ruff");
      Poochy.keystore.keyPair = (await browser).generate_keypair();
      //console.log('init, generated keys: ', Poochy.keystore.keyPair);
    }
    Poochy.authBrowserHandle = (await browser).create_browser(Debots.get('auth')!.name, Debots.get('auth')!.address);
  }

  static async authorize(): Promise<any | undefined> {
    return new Promise<string | undefined>(async (resolve, reject) => {
      browser.then(() => {
        if (Poochy.keystore.keyPair) {
          Poochy.getFlexClientAndUserId(Poochy.keystore.keyPair?.public).then(async (value) => {
            //console.log(value);
            if (value.flexClient) {
              Poochy._isAuthenticated = true;
              Poochy.flexClient = value.flexClient;
              Poochy.userId = value.userId;
              DebotClient.flexClient = value.flexClient;
              DebotClient.userId = value.userId;
              DebotClient.updateApiBrowser(Poochy.keystore.keyPair)
              Poochy.getUserIdAddress(value.flexClient, value.userId)
                .then(userIdAddress => {
                  //console.log('getUserIdAddress result => ', userIdAddress);
                  if (userIdAddress.addr) {
                    value.userIdAddress = userIdAddress.addr;
                  }
                  resolve(value);
                }).catch(error => {
                  resolve(undefined);
                });
            } else {
              resolve(undefined);
            }
          });
        }
      }, error => {
        reject();
        alert("Rejected: " + error);
      });
    });
  }

  static async isAuthDebotUpdateNeeded() {
    return (await Poochy.isUpdateNeeded(Poochy.flexClient)).res;
  }

  static async pollingUpdateAuthDebot(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let interval = setInterval(() => {
        Poochy.isAuthDebotUpdateNeeded().then(needed => {
          if (!needed) {
            clearInterval(interval);
            resolve(needed);
          }
        });
      }, 3000);
    });
  }

  static async authorizePolling(): Promise<any | undefined> {
    return new Promise<string | undefined>(async (resolve, reject) => {
      browser.then(() => {
        let interval = setInterval(() => {
          Poochy.checkAuthorization(resolve, reject, interval);
        }, 3000);
      }, error => {
        reject();
        alert("Rejected: " + error);
      });
    });
  }

  static async invokeAuthMessage (): Promise<string | undefined> {
    return new Promise<string | undefined>(async (resolve, reject) => {
      browser.then(async () => {
        if (!Poochy.authMessage && Poochy.keystore.keyPair?.public) {
          Poochy.authMessage = (await Poochy.getInvokeAuthMessage(Poochy.keystore.keyPair?.public, await Poochy.password, Poochy.username)).message;
          resolve(Poochy.authMessage);
        }
      }, error => {
        reject();
        alert("Auth message error: " + error);
      });
    });
  }

  private static async checkAuthorization (
    resolve: (value: any) => void,
    reject: (value: any) => void,
    interval: NodeJS.Timeout | undefined
  ): Promise<any> {
    if (Poochy._isAuthenticated) {
      if (interval) clearInterval(interval);
    } else if (Poochy.keystore.keyPair) {
      Poochy.getFlexClientAndUserId(Poochy.keystore.keyPair?.public).then(async (value) => {
        //console.log(value);
        if (value.flexClient) {
          Poochy._isAuthenticated = true;
          Poochy.flexClient = value.flexClient;
          Poochy.userId = value.userId;
          DebotClient.flexClient = value.flexClient;
          DebotClient.userId = value.userId;
          DebotClient.updateApiBrowser(Poochy.keystore.keyPair);
          Poochy.getUserIdAddress(value.flexClient, value.userId)
            .then(userIdAddress => {
              //console.log('getUserIdAddress result => ', userIdAddress);
              if (userIdAddress.addr) {
                value.userIdAddress = userIdAddress.addr;
              }
              resolve(value);
            }).catch(error => {
              resolve(undefined);
            });
          console.log(DebotClient.updateApiBrowser(Poochy.keystore.keyPair));
          if (interval) clearInterval(interval);
          //resolve(value);
        } else {

        }
      });
    }
  }


  // private static async checkAuthorization (
  //   resolve: (value: any) => void,
  //   reject: (value: any) => void,
  //   interval: NodeJS.Timeout | undefined
  // ): Promise<any> {

  //   if (!Poochy.authMessage && Poochy.keystore.keyPair?.public)
  //     Poochy.authMessage = (await Poochy.getInvokeAuthMessage(Poochy.keystore.keyPair?.public, await Poochy.password, Poochy.username)).message;

  //   if (Poochy._isAuthenticated) {
  //     if (interval) clearInterval(interval);
  //   } else if (Poochy.keystore.keyPair) {
  //     Poochy.getFlexClientAndUserId(Poochy.keystore.keyPair?.public).then(async (value) => {
  //       if (value.flexClient) {
  //         Poochy._isAuthenticated = true;
  //         Poochy.flexClient = value.flexClient;
  //         Poochy.userId = value.userId;
  //         console.log(DebotClient.updateApiBrowser(Poochy.keystore.keyPair?.public));
  //         if (interval) clearInterval(interval);
  //         resolve(value.userId);
  //       }
  //     });
  //   }
  // }

  static setEntered (
    isEntered: boolean
  ):void {
    if (isEntered)
      Poochy.Storage.setItem("bark", isEntered/*, {maxAge: 302400}*/);
    Poochy._isEntered = isEntered;
  }

  public static logOut(): void {
    Poochy._isEntered = false;
  }

  public static deleteSession(): void {
    Poochy.keystore.keyPair = undefined;
    Poochy.Storage.removeItem("woof");
    Poochy.Storage.removeItem("ruff");
    Poochy._isAuthenticated = false;
    Poochy._isEntered = false;
    //Poochy.keystore.keyPair = (await browser).generate_keypair();
    //Poochy.mode = "signup";
  }

  public static async enterCredentials({
    username,
    password,
    keyPublic,
    keyPrivate,
  }: {
    username: string,
    password: string,
    keyPublic?: string,
    keyPrivate?: string,
  }): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      Poochy.password = (await (await browser).sha256(Utils.stringToBase64(password.trim())));
      Poochy.username = username.trim();
      const deviceID = await Poochy.deviceID;

      if (Poochy.mode === "signup") {
        // new authentication
        // salt key
        Poochy.derivedKey = await Poochy.deriveKeyFromPasswordAndSalt(
          await password,
          await Poochy.saltKey(`${Poochy.username}/${deviceID}`)
        );

        if (keyPublic && keyPrivate) {
          console.log('set custom keys');
          Poochy.keystore.keyPair = {
            secret: keyPrivate.startsWith('0x') ? keyPrivate.substr(2) : keyPrivate,
            public: keyPublic.startsWith('0x') ? keyPublic.substr(2) : keyPublic,
          };
        } else {
          console.log('leave generated keys');
          //Poochy.keystore.keyPair = (await browser).generate_keypair();
        }

        //console.log('keys:', Poochy.keystore.keyPair);

        const { encStr, nonce } = await Poochy.encryptString(JSON.stringify(Poochy.keystore) as string, Poochy.derivedKey as string);
        // // store encrypted keys to localStorage
        Poochy.Storage.setItem("woof", encStr/*, {maxAge: 2147483647}*/);
        Poochy.Storage.setItem("ruff", nonce + encStr/*, {maxAge: 2147483647}*/);
        resolve(true);
        Poochy._isEntered = true;
        console.log('sign Up:: success');
        //console.log(`sign Up:: derivedKey`, await Poochy.derivedKey);
        //console.log(`sign Up:: nonce ${nonce}`);
        //console.log(`sign Up:: encStr ${encStr}`);
      } else {
        //console.log(`sign In:: deviceID ${deviceID}`);
        Poochy.derivedKey = await Poochy.deriveKeyFromPasswordAndSalt(
          await password,
          await Poochy.saltKey(`${Poochy.username}/${deviceID}`)
        );
        //console.log(`sign In:: derivedKey`, await Poochy.derivedKey);
        //console.log(`sign In:: nonce ${Poochy.nonceEncrypted.substring(0, Poochy.nonceEncrypted.length - Poochy.keystoreEncrypted.length)}`);
        //console.log(`sign In:: encStr ${Poochy.keystoreEncrypted}`);

        const decrypted = await Poochy.decryptString({
          encStr: Poochy.keystoreEncrypted,
          nonce: Poochy.nonceEncrypted.substring(0, Poochy.nonceEncrypted.length - Poochy.keystoreEncrypted.length),
        }, Poochy.derivedKey);

        //console.log('decrypted => ', decrypted);

        try {
          Poochy.keystore = JSON.parse(decrypted);
          Poochy._isEntered = true;
          console.log('logined success');
          resolve(true);
        } catch (error) {
          resolve(false);
          console.error("Login and password pair is not correct");
        }
      }
    });
  }

  public static isEntered():boolean {
    return Poochy._isEntered;
  }
  public static isAuthenticated():boolean {
    return Poochy._isAuthenticated;
  }

  private static async saltKey(
    deviceId: string
  ): Promise<Hex> {
    return (await (await browser).sha256(Utils.stringToBase64(deviceId)));
  }

  private static async deriveKeyFromPasswordAndSalt(
    password: string,
    salt: string,
  ): Promise<Hex> {
    const logN = 14;
    const r = 8;
    const p = 1;
    const dkLen = 32;
    const result: any = await (await browser).scrypt({
      password: Utils.stringToBase64(password),
      salt: Utils.stringToBase64(salt),
      log_n: logN,
      r,
      p,
      dk_len: dkLen,
    });
    return result;
  }

    private static async encryptString (string: string, pwDerivedKey: Hex): Promise<EncryptedString> {
      const random = await (await browser).generate_random_bytes(nonceLength);
      const nonce: Hex = Utils.base64ToHex(random);

      const encStr: Base64 = (
        await (await browser).chacha20({
          data: Utils.stringToBase64(string),
          nonce: nonce,
          key: pwDerivedKey,
        })
      );
      return {
        encStr,
        nonce: Utils.hexToBase64(nonce),
      };
  }

  private static async decryptString(encryptedStr: EncryptedString, pwDerivedKey: Hex): Promise<string> {
    const nonce = Utils.base64ToHex(encryptedStr.nonce);
    const decrypted = await (await browser).chacha20({
      data: encryptedStr.encStr,
      nonce: nonce,
      key: pwDerivedKey,
    });
    return Utils.base64ToString(decrypted);
  }

  private static buildManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('auth')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "chain": [],
      "abi": ${callback.authabi}
    }`);
  }

  static async getUserIdAddress(flexClient: string, userId: string):Promise<any> {
    logger.log(`Calling getUserIdAddress...\n`);
    const manifest = Poochy.buildManifest("getUserIdAddress", `{"userId": "${userId}", "flexClient": "${flexClient}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await Poochy.authBrowserHandle), manifest)
  }

  static async getInvokeUnwrapMessage(wallet: string):Promise<any> {
    logger.log(`Calling getInvokeUnwrapMessage... ${wallet}, ${DebotClient.flexClient}\n`);
    const manifest = Poochy.buildManifest("getInvokeUnwrapMessage", `{"wallet" : "${wallet}", "flexClient": "${DebotClient.flexClient}"}`);
    return await (await browser).run_browser((await Poochy.authBrowserHandle), manifest)
  }

  static async getFlexClientAndUserId(republic: string):Promise<any> {
    logger.log(`Calling getFlexClientAndUserId...\n`);
    const manifest = Poochy.buildManifest("getFlexClientAndUserId", `{"pubkey" : "0x${republic}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await Poochy.authBrowserHandle), manifest)
  }

  static async isUpdateNeeded(flexClient: string):Promise<any> {
    logger.log(`Calling getIsUpdateNeeded...\n`);
    const manifest = Poochy.buildManifest("isUpdateNeeded", `{"flexClient" : "${flexClient}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await Poochy.authBrowserHandle), manifest)
  }

  static async getInvokeAuthMessage(republic: string, salt: string, login: string):Promise<any> {
    logger.log(`Calling getInvokeAuthMessage...\n`);
    const manifest = Poochy.buildManifest("getInvokeAuthMessage", `{"pubkey" : "0x${republic}", "keyHash" : "0x${salt}", "login" : "${login}"}`);
    return await (await browser).run_browser((await Poochy.authBrowserHandle), manifest)
  }
}

(async () => {
  new Poochy();
})()
