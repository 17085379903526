import React, { Dispatch, useState, useRef, useEffect, ReactNode } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import cn from "classnames";
import {
  isMobile
} from "react-device-detect";

import Main from "./main";
import Content from "./content";
import { Header, Overlay, Icon } from "../components";
import QRCodeStyling from "qr-code-styling";
import { Authenticate } from "./auth";
import Poochy from "../client/poochy";
import { Debots } from "../client/client";
import GA from "../ga";
import FlexAPI from '../flex_charts/api/flexAPI';
import { setAuthorised, setUserGas } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "../controllers/useTheme";
import { ReactComponent as WarningIcon } from "../assets/images/warning.svg";

import { ActionTypes, RootState } from "../types";

export const USER_GAS_SUBSCRIPTION_ID = 'subscription_user_address_id_balance';
export const FLEXCLIENT_GAS_SUBSCRIPTION_ID = 'subscription_flex_client_address_id_balance';

const SurfConnect = ({
  showModal,
  handleClose,
  authenticateComponent,
  isEntered,
  showQRCode,
  url,
  onUrlOpen,
  updateAuthDebot,
  title,
  note,
}: {
  showModal: boolean,
  handleClose: any,
  authenticateComponent: ReactNode,
  isEntered: boolean,
  showQRCode: boolean,
  url?: string,
  onUrlOpen?: any,
  updateAuthDebot?: boolean,
  title?: string,
  note?: string,
}) => {


  const refQr = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const QRCode = new QRCodeStyling({
    width: 350,
    height: 350,
    type: "svg",
    data: url || `https://uri.ever.surf/debot/${Debots.get('auth')!.address}?message=${Poochy.authMessage}&net=${Debots.get('auth')!.alias}`,
    margin: 0,
    qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "L"
    },
    imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0,
        margin: 0
    },
    dotsOptions: {
        type: "rounded",
        color: theme === 'Dark' ? "#ffffff" : "#000000"
    },
    backgroundOptions: {
        color: "transparent"
    },
    image: "",
    cornersSquareOptions: {
        type: undefined,
        color: theme === 'Dark' ? "#ffffff" : "#000000"
    },
    cornersDotOptions: {
        type: undefined,
        color: theme === 'Dark' ? "#ffffff" : "#000000"
    }
  });

  useEffect(() => {
    if (refQr.current) {
      QRCode.update({
        data: url || `https://uri.ever.surf/debot/${Debots.get('auth')!.address}?message=${Poochy.authMessage}&net=${Debots.get('auth')!.alias}`
      });
      refQr.current.innerHTML = '';
      QRCode.append(refQr.current);
      refQr.current.querySelector("svg")!.setAttribute("viewBox", "0 0 350 350");
    }
  }, [showQRCode, showModal]);

  const surfAppLink = url || `https://uri.ever.surf/debot/${Debots.get('auth')!.address}?message=${Poochy.authMessage}&net=${Debots.get('auth')!.alias}`;
  const onLinkClick = onUrlOpen ? () => { window.open(surfAppLink, '_blank'); onUrlOpen(); } : undefined;

  return (
<Overlay
  show={showModal}
  onHide={handleClose}
  fullscreen={true}
  body={<div className={cn("surf-connect")}>
    <div className={cn({"active": !isEntered})}>{authenticateComponent}</div>
    <div className={cn("content-wrapper", "content", {"active": isEntered})}>
      <div className="svg-wrapper ">
        <div ref={refQr} className={cn("qrcode")}></div>
          <a
            target="_blank"
            rel="noreferrer"
            href={onUrlOpen ? undefined : surfAppLink}
            onClick={onLinkClick}
            className={cn("trade-table-button")}
            style={{cursor: 'pointer'}}
          >
              Open Surf app
          </a>
        </div>
      <div>
        <h5 className="headers headers-medium">{title || <span>To use Flex &mdash;</span>}</h5>
        <ol>
          <li>Open Surf on your phone</li>
          <li>Tap Scan QR <Icon icon={"qrcode"} /></li>
          <li>Point your phone at this screen to confirm login</li>
        </ol>
        {note ?
          <div className="d-flex flex-row">
            <WarningIcon/>
            <span className="ms-2">{note}</span>
          </div> :
          <a href="">Need help to get started?</a>}
      </div>
    </div>
    </div>}
  footer={<div className={cn("platforms", "paragraph", "paragraph-note")}>
    Download Surf:
    <ul>
      <a target="_blank" rel="noreferrer" href="https://itunes.apple.com/us/app/ton-surf/id1481986831?mt=8"><li className={cn("ios")}><Icon icon={"apple"} />App Store</li></a>
      <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=surf.ton"><li className={cn("android")}><Icon icon={"google-play"} />Google Play</li></a>
      <a target="_blank" rel="noreferrer" href="https://github.com/tonlabs/Surf-Releases/releases"><li className={cn("github")}><Icon icon={"github"} />GitHub</li></a>
    </ul>
  </div>}
/>)};

const Router = () => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalUpdateAuth, setShowModalUpdateAuth] = useState<boolean>(false);
  const [showAuth, setShowAuth] = useState<boolean>(Boolean(Poochy.authMessage));
  const [entered, setEntered] = useState<boolean>(Poochy.isEntered());
  const [error, setError] = useState<boolean | string>(false);
  const [url, setUrl] = useState<string>('');
  const [closableOnUrl, setClosableOnUrl] = useState<boolean>(false);

  const setModalUrl = (url: string, closable: boolean = false) => {
    setUrl(url);
    setClosableOnUrl(closable);
  }

  const handleClose = () => {
    setUrl('');
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);
  const toggleModal = showModal ? () => handleClose() : () => handleShow();

  const onUrlOpen = closableOnUrl ? () => handleClose() : null;

  const location:string = useLocation().pathname.split('/').filter(Boolean)[0];

  const handleEnter = async ({
    username,
    password,
    keyPublic,
    keyPrivate,
  }: {
    username: string,
    password: string,
    keyPublic?: string,
    keyPrivate?: string,
  }) => {
    Poochy.enterCredentials({
      username: username,
      password: password,
      keyPublic,
      keyPrivate,
    }).then((value) => {
      if (value) {
        Poochy.setEntered(true);
        setEntered(true);
        Poochy.invokeAuthMessage().then((value) => {
          if (value) {
            setShowAuth(true);
            Poochy.authorize().then((value) => {
              if (value) {
                dispatch(setAuthorised(value));
                GA.sendAuthorized(value.userId);
                setShowModal(false);
                console.log('call isAuthDebotUpdateNeeded....');
                Poochy.isAuthDebotUpdateNeeded().then(isNeedeed => {
                  console.log('call isAuthDebotUpdateNeeded.... ready, ', isNeedeed);
                  if (isNeedeed) {
                    setShowModalUpdateAuth(true);
                    console.log('call pollingUpdateAuthDebot....');
                    Poochy.pollingUpdateAuthDebot().then(() => {
                      console.log('call pollingUpdateAuthDebot.... ready with true');
                      setShowModalUpdateAuth(false);
                    });
                  }
                });
              }
              else
                Poochy.authorizePolling().then((value) => {
                  if (value) {
                    dispatch(setAuthorised(value));
                    GA.sendAuthorized(value.userId);
                    setShowModal(false);
                    console.log('call isAuthDebotUpdateNeeded....');
                    Poochy.isAuthDebotUpdateNeeded().then(isNeedeed => {
                      console.log('call isAuthDebotUpdateNeeded.... ready, ', isNeedeed);
                      if (isNeedeed) {
                        setShowModalUpdateAuth(true);
                        console.log('call pollingUpdateAuthDebot....');
                        Poochy.pollingUpdateAuthDebot().then(() => {
                          console.log('call pollingUpdateAuthDebot.... ready with true');
                          setShowModalUpdateAuth(false);
                        });
                      }
                    });
                  }
                });
            });
          }
        });

      } else {
        setError("These credentials didn't work out");
        setTimeout(() => {
          setError(false);
        }, 2000);
      }
    }).catch(error => console.error(error.message));
  }

  const { authorized } = useSelector((state: RootState) => state.dashboard);

  // Subscribe User Gas
  useEffect(() => {
    if (authorized) {
      if (!(window as any).totalGas) (window as any).totalGas = {userIdAddress: 0, flexClient: 0};
      Promise.all([
        FlexAPI.flexBalance(authorized?.userIdAddress),
        FlexAPI.flexBalance(authorized?.flexClient),
      ]).then((balances: (number | undefined)[]) => {
        console.log('balances (user, flex) => ', balances);
        (window as any).totalGas.userIdAddress = balances[0] || 0;
        (window as any).totalGas.flexClient = balances[1] || 0;
        const gas = balances.reduce((sum, balance) => (sum || 0) + (balance || 0), 0);
        dispatch(setUserGas(gas));
      })
      //FlexAPI.flexBalance(authorized?.userIdAddress).then((balance: number | undefined) => dispatch(setUserGas(balance)));

      FlexAPI.unsubscribe(USER_GAS_SUBSCRIPTION_ID).then(() => {
        FlexAPI.subscribeBalance(authorized?.userIdAddress, USER_GAS_SUBSCRIPTION_ID, (balance: number | undefined) => {
          console.log('UPDATE USER GAS => ', balance);
          (window as any).totalGas.userIdAddress = (balance || 0);
          dispatch(setUserGas((window as any).totalGas.userIdAddress + (window as any).totalGas.flexClient));
        });
      });

      FlexAPI.unsubscribe(FLEXCLIENT_GAS_SUBSCRIPTION_ID).then(() => {
        FlexAPI.subscribeBalance(authorized?.flexClient, FLEXCLIENT_GAS_SUBSCRIPTION_ID, (balance: number | undefined) => {
          console.log('UPDATE FLEX CLIENT GAS => ', balance);
          (window as any).totalGas.flexClient = (balance || 0);
          dispatch(setUserGas((window as any).totalGas.userIdAddress + (window as any).totalGas.flexClient));
        });
      });
    }

    return () => {
      FlexAPI.unsubscribe(USER_GAS_SUBSCRIPTION_ID);
      FlexAPI.unsubscribe(FLEXCLIENT_GAS_SUBSCRIPTION_ID);
    }
  }, [authorized?.userIdAddress, authorized?.flexClient]);

  return (<>
    <div className={cn("ws-app", useLocation!().pathname.split('/').filter(Boolean)[0], {"isMobile": isMobile, "index-page": !location})}>
      <Header
        showModal={showModal}
        showModalUpdateAuth={showModalUpdateAuth}
        toggleModal={toggleModal}
      />
        <main>
          <Routes>
            <Route path="/legal-notes/:id" element={<Content />} />
            <Route path="/terminate" element={<Navigate to='/'/>} />
            <Route path="/" element={<Main
              showModal={showModal}
              handleShow={handleShow}
              setModalUrl={setModalUrl}
            />} />
            <Route path="/index.html" element={<Main
              showModal={showModal}
              handleShow={handleShow}
              setModalUrl={setModalUrl}
            />} />
          </Routes>
        </main>
        <SurfConnect
          url={url}
          onUrlOpen={onUrlOpen}
          showModal={showModal}
          handleClose={handleClose}
          authenticateComponent={
            <Authenticate
              handleEnter={handleEnter}
              handleLogout={Poochy.deleteSession}
              mode={Poochy.mode}
              error={error}
            />}
          isEntered={entered}
          showQRCode={showAuth}
        />
        <SurfConnect
          url={''}
          title={'Flex has been updated. To continue trading, sync your account:'}
          note={'This will cancel all your open orders'}
          authenticateComponent={null}
          handleClose={()=>{}}
          showModal={showModalUpdateAuth}
          isEntered={true}
          updateAuthDebot
          showQRCode={showAuth}
        />
      </div>
    </>
  );
};

export default Router;


// {showAuth
//   ? <Switch>
//       <Route path="/terminate">
//         <Redirect to='/'/>
//       </Route>
//       <Authenticate
//         handleEnter={handleEnter}
//         handleLogout={Poochy.deleteSession}
//         mode={Poochy.mode}
//         error={error}
//       />
//     </Switch>
//   : <>
